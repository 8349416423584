
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { dealGoodsData, mostViewGoodsHttp, goodsListHttp } from "~/api/goods";

@Component({
  name: "detailHotBrowse",
  components: {
    newImg: require("~/components/comm/newImg/index.vue").default,
  },
})
export default class detailHotBrowse extends Vue {
  @Prop(Number) game_?: number;
  @Prop(Number) goodsTypeId_?: number;
  @Prop(Number) flag_?: number;
  @Prop({ default: () => {} }) goodsInfo_?: any;
  public errorToastFn: any;
  private size: number = 5; // 每页记录数
  private page: number = 1; // 当前页码
  private goodsSeeList: any = [];
  private filters: any = {
    pages: this.page,
    pageSize: this.size,
    gameId: this.game_,
    goodsTypeId: this.goodsTypeId_,
  };
  private msrhdyzFilters = ["campId", "clanId", "jobsId"];
  private mshjfFilters = ["campId", "clanId", "jobsId"];
  private yjwjFilters = ["areaId"];
  private sw4pcilters = ["areaId"];

  mounted() {
    // let filtersData = this.goodsInfo_.goodsShowTileList.filter((v:any)=>v.type === 'similarGoods')//相似商品,取详情配置中类型为similarGoods的数据
    // if(filtersData.length > 0){
    //   let filtersDataKey = filtersData[0].key.split(',')//将取出来的数据分割
    //   filtersDataKey.map((v:any)=>{//循环赋值
    //     this.filters[v] = this.goodsInfo_[v]
    //   })
    // }
    this.filters.gameId = this.filters.gameId
      ? this.filters.gameId
      : this.$store.state.web.distributorsObject.distBaseConf.teGameId;
    // let filtersDataKey:any = []
    if (this.filters.gameId === 17) {
      //魔兽燃烧的远征
      this.msrhdyzFilters.map((v: any) => {
        this.filters[v] = this.goodsInfo_[v];
      });
    } else if (this.filters.gameId === 86) {
      //永劫无间
      this.yjwjFilters.map((v: any) => {
        this.filters[v] = this.goodsInfo_[v];
      });
    } else if (this.filters.gameId === 37) {
      //魔兽经典怀旧服

      this.mshjfFilters.map((v: any) => {
        this.filters[v] = this.goodsInfo_[v];
      });
    } else if (this.filters.gameId === 49) {
      //神武4
      this.sw4pcilters.map((v: any) => {
        this.filters[v] = this.goodsInfo_[v];
      });
    }

    this.getGoodsSee();
  }
  @Emit()
  // 拉取大家都在看数据列表
  async getGoodsSee() {
    // let gameId = this.game_
    // let goodsTypeId = this.goodsTypeId_
    if (!this.filters.gameId || !this.filters.goodsTypeId) {
      this.filters.gameId =
        this.$store.state.web.distributorsObject.distBaseConf.teGameId;
      this.filters.goodsTypeId = 1;
      this.size = 1;
    }
    try {
      let { data } = await goodsListHttp({ params: this.filters });
      if (this.errorToastFn(data)) {
        console.log("dhsajhdkjask", data.data);

        if (data.data && data.data.goodsList.length > 0) {
          data.data.goodsList = data.data.goodsList.filter(
            (v: any) => v.goodsSn !== this.goodsInfo_.goodsSn
          ); //去除返回列表中原商品
          this.goodsSeeList = dealGoodsData(data.data);
        }
      } else {
        this.goodsSeeList = [];
      }
    } catch (error) {
      this.goodsSeeList = [];
    }
  }
  @Emit()
  // 拆分区服信息
  getArea(item: any, game: number) {
    if (game == 1) {
      return `${item.areaName}-${item.serverName}`;
    } else {
      return item.serverName;
    }
  }
  @Emit()
  // ‘换一换’按钮
  changeSee() {
    this.page = this.page + 1;
    this.getGoodsSee();
  }
}
