
import { getTagsListHttp } from "~/api/goods";
  import { State, Getter, Action, Mutation, namespace } from "vuex-class";
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"detailAccountScreenshot",
    components: {
      newImg3: require('~/components/comm/newImg/index3.vue').default,
      imgPreviewViewer: require('~/components/comm/imgPreviewViewer/index.vue').default,
    },
  })
  export default class detailAccountScreenshot extends Vue {
    @State((state) => state.web.distributorsObject) distributorsObject!: any;
    @Prop(Object) goodsInfo_?: any
    private showBox:boolean=false//控制查看图片弹窗显示

    public goodLinghtLabel:any = null;
    private imageBoxArea:Array<any>=[]//查看图片
    mounted(){
      this.handleGameTags(this.goodsInfo_.gameId)
    }
    get filterImageArray() {
      try {
        return this.goodsInfo_.goodsImages
        console.log(this.goodsInfo_.goodsImages,'wefjwkefwf')
        if(this.goodsInfo_.goodsImages && this.goodsInfo_.goodsImages.length > 0) {
          console.log('进来123')
          if(this.goodsInfo_.distId==this.$store.state.web.distributorsObject.distId && this.distributorsObject && this.distributorsObject.distBaseConf.watermark) {
            let tempArr = JSON.parse(JSON.stringify(this.goodsInfo_.goodsImages))
            tempArr.forEach((v:any) => {
              if(v.url && v.url.indexOf('nocs-big') == -1) {
                v.url = v.url + this.distributorsObject.distBaseConf.watermark
              }
            })
            console.log('进来789', tempArr)
            return tempArr
          } else {
            console.log('进来456')
            return this.goodsInfo_.goodsImages
          }
        } else {
          console.log('sdfskdljfklsjf')
          return []
        }
      } catch (error) {
        console.log('错误了', error)
        return []
      }
    }


    
// 获取指定游戏的标签数据
async  handleGameTags(gameId:any) {
      let params = { gameId: this.goodsInfo_.gameId }

  let result:any = await getTagsListHttp({params});
  if(result.data.code === 0){
    let showFirstImge = result.data.data.find((item:any)=>{
        return item.labelId === 12
    });


    if(this.goodsInfo_.securitService){
      this.goodsInfo_.securitService.split(",").forEach((item:any)=>{
							if(Number(item) === 12){
								this.goodLinghtLabel = item
							}
					});
    }
    
    
    if(showFirstImge.publicizeImage && this.goodLinghtLabel){
      this.goodsInfo_.goodsImages.unshift({url:showFirstImge.publicizeImage})
    }
  }
}


    
    @Emit()
    //预览图片
    openImages(i:number){
      this.showBox=true
      this.imageBoxArea=[]
      let newUrl:string='https://oss1.hk1686.com/'
      // if (window && window.location.host && this.goodsInfo_.distId==this.$store.state.web.distributorsObject.distId) {
      //   newUrl=`http://img.${window.location.host}.dianjinghou.cn/`
      // }
      if(this.goodsInfo_.distId==this.$store.state.web.distributorsObject.distId && this.distributorsObject && this.distributorsObject.distBaseConf.watermark) {

        
        this.goodsInfo_.goodsImages.map((res:any)=>{
          if(res.url && res.url.indexOf('nocs-big') == -1&&this.goodsInfo_.gameId!==149) {
          

            this.imageBoxArea.push(`${newUrl}${res.url}${this.distributorsObject.distBaseConf.watermark}`)

          } else {

            

            this.imageBoxArea.push(`${newUrl}${res.url}`)
          }
        })
      } else {

       

        this.goodsInfo_.goodsImages.map((res:any)=>{
          this.imageBoxArea.push(`${newUrl}${res.url}`)
        })
      }

      this.$nextTick(() => {
        (this.$refs.dlg as any).show(i)
      })
    }
  }
