var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"detailHotBrowse_specil"},[_c('div',{staticClass:"detailHotBrowse",class:_vm.flag_ === 0
        ? 'detailHotBrowse_scroller_normal'
        : _vm.flag_ === 1
        ? 'detailHotBrowse_scroller_float'
        : 'detailHotBrowse_scroller_bottom',attrs:{"id":"alpha"}},[_c('div',{staticClass:"detailHotBrowse_label",staticStyle:{"bottom":"auto"}},[_c('div',{staticClass:"detailHotBrowse_title"},[_vm._v("相似商品")]),_vm._v(" "),_c('span',{staticClass:"detailHotBrowse_label_change",on:{"click":function($event){return _vm.openUrl(`/wares/?gid=${_vm.game_}&gt=${_vm.goodsTypeId_}`)}}},[_vm._m(0)])]),_vm._v(" "),_vm._l((_vm.goodsSeeList.goodsList),function(item,index){return (index < 5)?_c('div',{key:index,staticClass:"detailHotBrowse_item",on:{"click":function($event){return _vm.openGoodsDetail(
          { goodsSn: item.goodsSn, goodsTypeId: item.goodsTypeId },
          '_blank'
        )}}},[_c('div',{staticClass:"detailHotBrowse_item_img"},[_c('div',{staticClass:"detailHotBrowse_img_bg"},[_c('new-img',{attrs:{"imgTitle_":'大家都在看：' + item.bigTitle,"url_":item.thumbnail,"waterMark_":item.distId == _vm.$store.state.web.distributorsObject.distId}})],1)]),_vm._v(" "),_c('div',{staticClass:"detailHotBrowse_item_title-txt"},[_c('span',{attrs:{"title":item.bigTitle}},[_vm._v(_vm._s(item.bigTitle))])]),_vm._v(" "),_c('div',{staticClass:"detailHotBrowse_item_area"},[_vm._v("\n        "+_vm._s(_vm.getArea(item, item.gameId))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"detailHotBrowse_item_price"},[_c('div',{staticClass:"detailHotBrowse_price_price1"},[_vm._v("￥"+_vm._s(item.price))]),_vm._v(" "),_c('div',{staticClass:"detailHotBrowse_time"},[_vm._v("\n          "+_vm._s(_vm.timeFormatUser(item.createTime, "YYYY-MM-DD"))+"\n        ")])])]):_vm._e()})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v("更多")]),_vm._v(" "),_c('img',{attrs:{"src":require("static/image/arrowicon/right.png")}})])
}]

export { render, staticRenderFns }